import {
  Box,
  CircularProgress,
  CustomAlert,
  FormControlLabel,
  Grid,
  InputLabel,
  LoadingButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useFormik,
  yup,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientForm,
  errorCreateClient,
  errorsArray,
  isLoadingClients,
  isLoadingCreateClient,
  sendDataForm,
  sendEditClient,
  setEditClientArray,
  setResetVars,
  successCreateClient,
  successSendDataForm,
  successSendDataFormEdit,
  validateArrayEmailsState,
  validateArrayFieldsState,
} from "../../../features/enki/enki";
import { getBodySendClient } from "../../../helpers/helperEnki";
import { ObjectFormikValues } from "../../../models/enki";
import { AppDispatch } from "../../../store/store";
import FormArrayClient from "./FormArrayClient";

let formik;

const FormClient = () => {
  const clientForms = useSelector(clientForm);
  const isLoading = useSelector(isLoadingClients);
  const successSendData = useSelector(successSendDataForm);
  const errorCreateUser = useSelector(errorCreateClient);
  const successCreateUser = useSelector(successCreateClient);
  const loadingCreateClient = useSelector(isLoadingCreateClient);
  const successFormEdit = useSelector(successSendDataFormEdit);
  const errors = useSelector(errorsArray);

  const initialValues = {
    names: "",
    last_names: "",
    username: "",
    password: "",
    legal_id_type: "",
    legal_id_code: "",
    verification_code: "",
    country: "",
    city: "",
    emails: [
      {
        name: "emails0",
        id: "",
        value: "",
        priority: "",
      },
    ],
    phones: [
      {
        name: "phones0",
        id: "",
        value: "",
        priority: "",
      },
    ],
    checkedTypePerson: false,
  };

  const validationSchema = yup.object({
    checkedTypePerson: yup.boolean(),
    names: yup.string().required("Es requerido"),
    last_names: yup
      .string()
      .nullable()
      .when("checkedTypePerson", {
        is: true,
        then: (schema) => schema,
        otherwise: (schema) => schema.required("Es requerido"),
      }),
    verification_code: yup
      .number()
      .nullable()
      .when("checkedTypePerson", {
        is: true,
        then: (schema) =>
          schema
            .required("El campo es requerido")
            .min(0, `El numero no es valido, debe estar en un rango de 0 a 9`)
            .max(9, `El numero no es valido, debe estar en un rango de 0 a 9`)
            .typeError("Debe ser tipo númerico, y estar en el rango de 0 a 9"),
        otherwise: (schema) => schema,
      }),
    legal_id_type: yup
      .string()
      .test("validate_legal_id_type", "Es requerido", (value, context) => {
        const from = (context as any)?.from;
        const legal_id_type = value?.trim();
        if (from && from.length > 0) {
          const legal_id_code: string = from[0].value.legal_id_code ?? "";
          if (
            (legal_id_type == "" || !legal_id_type) &&
            legal_id_code &&
            legal_id_code != ""
          ) {
            return false;
          }
        }
        return true;
      }),
    legal_id_code: yup
      .string()
      .test("validate_legal_id_code", "Es requerido", (value, context) => {
        const from = (context as any)?.from;
        const legal_id_code = value?.trim();
        if (from && from.length > 0) {
          const legal_id_type: string = (
            from[0].value.legal_id_type ?? ""
          ).trim();
          if (
            (legal_id_code == "" || !legal_id_code) &&
            legal_id_type &&
            legal_id_type != ""
          ) {
            return false;
          }
        }
        return true;
      }),
  });
  const dispatch = useDispatch<AppDispatch>();

  formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      if (
        Object.keys(errors.emails).length == 0 &&
        Object.keys(errors.phones).length == 0
      ) {
        const body = getBodySendClient(formik.values);
        if (Object.keys(clientForms).length > 0) {
          dispatch(sendEditClient(body));
        } else {
          dispatch(sendDataForm(body));
        }
      }
    },
  });

  const setValuesToFormArray = (
    object: ObjectFormikValues[],
    field: string,
    type?: string
  ) => {
    const singularFiled = field.replace("s", "");

    let formatValue = object
      .map((value, index) => {
        return {
          name: `${field}${index}`,
          value: `${value[singularFiled]}`,
          priority: value.priority,
          id: value.id,
        };
      })
      .sort((a, b) => (a.priority < b.priority ? -1 : 1));

    if (type != "edit" && object.length % 2 != 0) {
      formatValue = [
        ...formatValue,
        {
          name: `${field}${object.length}`,
          value: "",
          priority: "",
          id: null,
        },
      ];
    }

    formik.setFieldValue(field, formatValue);
  };

  useEffect(() => {
    if (Object.keys(clientForms).length > 0) {
      formik.setFieldValue("names", clientForms?.pii?.names);
      formik.setFieldValue("last_names", clientForms?.pii?.last_names);
      formik.setFieldValue("username", clientForms?.username);
      formik.setFieldValue(
        "checkedTypePerson",
        clientForms.pii.legal_id_type === "NIT"
      );

      setValuesToFormArray(clientForms?.pii?.phones ?? [], "phones", "edit");
      setValuesToFormArray(clientForms?.pii?.emails ?? [], "emails", "edit");

      formik.setFieldValue("legal_id_type", clientForms?.pii?.legal_id_type);
      formik.setFieldValue("legal_id_code", clientForms?.pii?.legal_id_code);
      formik.setFieldValue(
        "verification_code",
        clientForms?.pii?.verification_code
      );

      dispatch(
        setEditClientArray({
          phones: clientForms?.pii?.phones,
          emails: clientForms?.pii?.emails,
        })
      );
    }
  }, [clientForms]);

  useEffect(() => {
    if (formik.values.checkedTypePerson) {
      formik.setFieldValue("legal_id_type", "NIT");
      formik.setFieldValue("last_names", "");
    } else {
      formik.setFieldValue("verification_code", "");
    }
  }, [formik.values.checkedTypePerson]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid item xs={12} md={11} sx={{ mb: "30px" }}>
          <Box className="Text-client-button-enki">
            {Object.keys(clientForms).length > 0 ? "Editar" : "Nuevo"} cliente
          </Box>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        {errorCreateUser != "" && (
          <CustomAlert
            onClose={() => {
              dispatch(setResetVars());
            }}
            sx={{ width: "100%", mb: "20px" }}
            severity="error"
            text={errorCreateUser}
          />
        )}
        {successCreateUser != "" && (
          <CustomAlert
            onClose={() => {
              dispatch(setResetVars());
            }}
            sx={{ width: "100%", mb: "20px" }}
            severity="success"
            text={successCreateUser}
          />
        )}
        {successFormEdit != "" && (
          <CustomAlert
            onClose={() => {
              dispatch(setResetVars());
            }}
            sx={{ width: "100%", mb: "20px" }}
            severity="success"
            text={successFormEdit.toString()}
          />
        )}
      </Grid>
      {isLoading && (
        <Box className="Loading-flex">
          <CircularProgress />
        </Box>
      )}
      <Box
        className="Container-client"
        sx={{ display: isLoading ? "none" : "block" }}
      >
        <Box className="Header-client"> Datos Cliente </Box>
        <Grid sx={{ flexGrow: 1 }} container>
          <Grid item xs={12} sx={{ mb: "30px", pl: "10px", mt: "20px" }}>
            <FormControlLabel
              disabled={successSendData}
              value="start"
              sx={{ margin: "0px" }}
              control={
                <Switch
                  color="warning"
                  checked={formik.values.checkedTypePerson}
                  onChange={(_event, checked) => {
                    formik.setFieldValue("checkedTypePerson", checked);
                  }}
                />
              }
              label={"Persona juridica"}
              labelPlacement="start"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={formik.values.checkedTypePerson ? 12 : 6}
            sx={{ mb: "30px", pl: "10px" }}
          >
            <InputLabel shrink className="Input-label">
              {formik.values.checkedTypePerson ? "Razón social" : "Nombre"}
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                fullWidth
                placeholder="Escribir nombre"
                name="names"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
                disabled={successSendData}
                value={formik.values.names}
                onChange={formik.handleChange}
                error={formik.touched.names && Boolean(formik.errors.names)}
                helperText={formik.touched.names && formik.errors.names}
              />
            </Box>
          </Grid>
          {!formik.values.checkedTypePerson && (
            <Grid item xs={12} md={6} sx={{ mb: "30px", pl: "10px" }}>
              <InputLabel shrink className="Input-label">
                Apellido
              </InputLabel>
              <Box className="TextField-without-border-radius Text-field-filters">
                <TextField
                  fullWidth
                  name="last_names"
                  placeholder="Escribir apellido"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%", borderRadius: "14px" }}
                  disabled={successSendData}
                  value={formik.values.last_names}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_names &&
                    Boolean(formik.errors.last_names)
                  }
                  helperText={
                    formik.touched.last_names && formik.errors.last_names
                  }
                />
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={formik.values.checkedTypePerson ? 4 : 6}
            sx={{ mb: "30px", pl: "10px" }}
          >
            <InputLabel shrink className="Input-label">
              Tipo de documento
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                fullWidth
                variant="outlined"
                name="legal_id_type"
                select
                sx={{ width: "100%" }}
                disabled={successSendData || formik.values.checkedTypePerson}
                value={formik.values.legal_id_type || " "}
                onChange={formik.handleChange}
                error={
                  formik.touched.legal_id_type &&
                  Boolean(formik.errors.legal_id_type)
                }
                helperText={
                  formik.touched.legal_id_type && formik.errors.legal_id_type
                }
              >
                <MenuItem value={" "}>
                  <Typography sx={{ color: "#c8c8c8" }}>
                    Seleccione documento
                  </Typography>
                </MenuItem>
                <MenuItem value={"CC"}>CC</MenuItem>
                <MenuItem value={"CE"}>CE</MenuItem>
                <MenuItem value={"PP"}>PP</MenuItem>
                {
                  formik.values.checkedTypePerson && <MenuItem value={"NIT"}>NIT</MenuItem>
                }
              </TextField>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={formik.values.checkedTypePerson ? 4 : 6}
            sx={{ mb: "30px", pl: "10px" }}
          >
            <InputLabel shrink className="Input-label">
              Número de documento
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                fullWidth
                name="legal_id_code"
                placeholder="Escribir número de documento"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
                disabled={successSendData}
                value={formik.values.legal_id_code}
                onChange={formik.handleChange}
                error={
                  formik.touched.legal_id_code &&
                  Boolean(formik.errors.legal_id_code)
                }
                helperText={
                  formik.touched.legal_id_code && formik.errors.legal_id_code
                }
              />
            </Box>
          </Grid>
          {formik.values.checkedTypePerson && (
            <Grid item xs={12} md={4} sx={{ mb: "30px", pl: "10px" }}>
              <InputLabel shrink className="Input-label">
                Dígito de verificación
              </InputLabel>
              <Box className="TextField-without-border-radius Text-field-filters">
                <TextField
                  name="verification_code"
                  placeholder="Escribir dígito de verificación"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%", borderRadius: "14px" }}
                  disabled={successSendData}
                  value={formik.values.verification_code}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.verification_code &&
                    Boolean(formik.errors.verification_code)
                  }
                  helperText={
                    formik.touched.verification_code &&
                    formik.errors.verification_code
                  }
                />
              </Box>
            </Grid>
          )}
          <FormArrayClient
            method={Object.keys(clientForms).length > 0 ? "edit" : "add"}
            type="emails"
            successSendData={successSendData}
            formik={formik}
          />
          <FormArrayClient
            method={Object.keys(clientForms).length > 0 ? "edit" : "add"}
            type="phones"
            successSendData={successSendData}
            formik={formik}
          />
          <Grid xs={12}>
            <Box display="flex" justifyContent={"end"}>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                disabled={loadingCreateClient}
                onClick={() => {
                  const body = getBodySendClient(formik.values);
                  const phones = body.phones.find(
                    (phone) => phone.phone != "" || phone.priority != ""
                  );

                  if (formik.values.checkedTypePerson || phones != null) {
                    dispatch(validateArrayFieldsState(body));
                  } else {
                    dispatch(validateArrayEmailsState(body));
                  }
                }}
                sx={{ maxWidth: "150px", minHeight: "48px" }}
              >
                <Box sx={{ fontSize: "14px" }}>Guardar Cliente</Box>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default FormClient;
