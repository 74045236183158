import {
  Box,
  Button,
  DeleteOutlineIcon,
  EditOutlinedIcon,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@enerbit/base";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clients,
  count,
  getClients,
  handlePageChange,
  page,
} from "../../features/enki/enki";
import { Clients } from "../../models/enki";
import { AppDispatch } from "../../store/store";

const TableEnki = () => {
  const countInvoices = useSelector(count);
  const pageInvoices = useSelector(page);
  const dispatch = useDispatch<AppDispatch>();
  const items = useSelector(clients);
  const navigate = useNavigate();

  const _getFirsPhone = (client: Clients) => {
    if (client?.pii?.phones?.length > 0) {
      return client?.pii?.phones[0].phone;
    }
    return null;
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table className="Table-general" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Nombre de usuario</TableCell>
              <TableCell align="left">Tipo documento</TableCell>
              <TableCell align="left">Documento</TableCell>
              <TableCell align="left">Teléfono</TableCell>
              <TableCell align="left">Grupo de servicios</TableCell>
              <TableCell align="left"># Servicios</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      {row.pii != null
                        ? row?.pii?.names + " " + (row?.pii?.last_names ?? '')
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row.pii != null ? row.username : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row.pii != null ? row?.pii?.legal_id_type : ""}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "700" }}>
                      {row.pii != null ? row?.pii?.legal_id_code : ""}
                    </TableCell>
                    <TableCell align="left">
                      {_getFirsPhone(row) ?? ""}
                    </TableCell>
                    <TableCell align="left">
                      {row.total_service_groups}
                    </TableCell>
                    <TableCell align="left">{row.total_services}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: "inline-flex" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ minWidth: "59px", borderRadius: "14px" }}
                          className="Loading-button"
                          onClick={() => {
                            navigate("/create?q=" + row.pii.app_user_id);
                          }}
                        >
                          <EditOutlinedIcon sx={{ fontSize: "21px" }} />
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          className="Loading-button Button-xs"
                          sx={{ ml: "10px" }}
                          disabled
                          onClick={() => {}}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "21px" }} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="Pagination-tables">
        <Pagination
          count={countInvoices}
          shape="rounded"
          page={pageInvoices + 1}
          sx={{ margin: "0.3rem 0 1rem 0" }}
          onChange={(event: ChangeEvent<unknown>, value: number) => {
            dispatch(handlePageChange(value - 1));
            dispatch(getClients());
          }}
        />
      </Box>
    </>
  );
};

export default TableEnki;
