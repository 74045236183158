import {
  Box,
  Button,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  Grid,
  InputLabel,
  serviceNames,
  thirdPartyServiceNames,
} from "@enerbit/base";
import { Fragment } from "react";
import { PropsContainerServices } from "../../models/enki";

const ContainerServices = ({ services }: PropsContainerServices) => {
  return (
    <Fragment>
      {services.map((item, index) => (
        <Grid container key={index} className="Container-services">
          <Grid item xs={3}>
            <InputLabel
              shrink
              className="Input-label"
              sx={{ fontSize: "0.75rem" }}
            >
              Tipo de servicio
            </InputLabel>
            <Box className="Group-service-item">
              {(item?.service_type_name
                ? thirdPartyServiceNames[item.service_type_name]
                : undefined) ||
                (item?.service_type_name
                  ? serviceNames[item.service_type_name]
                  : null)}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <InputLabel
              shrink
              className="Input-label"
              sx={{ fontSize: "0.75rem" }}
            >
              Proveedor de servicio
            </InputLabel>
            <Box className="Group-service-item">enerBit</Box>
          </Grid>
          <Grid item xs={3}>
            <InputLabel
              shrink
              className="Input-label"
              sx={{ fontSize: "0.75rem" }}
            >
              Dirección
            </InputLabel>
            <Box className="Group-service-item">{item.estate_address}</Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="Container-groups-client">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="Loading-button Button-groups-client"
                disabled
              >
                <EditOutlinedIcon sx={{ fontSize: "16px" }} />
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ minWidth: "40px", borderRadius: "14px" }}
                className="Loading-button Button-groups-client"
                disabled
              >
                <DeleteOutlinedIcon sx={{ fontSize: "16px" }} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

export default ContainerServices;
