import {
  Box,
  Button,
  CloseIcon,
  Grid,
  InputLabel,
  TextField,
  Typography,
  AddCircleOutlineIcon,
  MenuItem
} from "@enerbit/base";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { errorsArray } from "../../../features/enki/enki";
import { PropsFormArray } from "../../../models/enki"; 

const FormArrayClient = ({ type, successSendData, formik }: PropsFormArray) => {

  const errors = useSelector(errorsArray); 
  const titleAddArray = type == "emails" ? "AGREGAR CORREO ELECTRÓNICO" : "AGREGAR TELÉFONO";
  const titleAddButton = type == "emails" ? "Agregar nuevo correo" : "Agregar nuevo teléfono"; 

  const deletePosition = (index: number) => 
  {
    formik.values[type].splice(index, 1);
    formik.setFieldValue(type, formik.values[type]);
  }

  const addPositions = () => 
  {
    formik.setFieldValue(type, [...formik.values[type], {
      name: `${type}${formik.values[type].length}`,
      priority: "",
      value: "",
      id: ""
    }]); 
  }
 
  const componentGrid = () => {
    return formik.values[type].map((value, index) =>
      {
        return <Fragment key={index}>
          <Grid item xs={12} md={Number(index) != 0 ? 3 : 4} sx={{ mb: "30px", pl: "10px" }}>
            <InputLabel shrink className="Input-label">
              {type == "emails" ? "Correo electrónico" : "Teléfono"}
            </InputLabel> 
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                fullWidth
                placeholder={type == "emails" ? "Escribir correo" : "Escribir teléfono"}
                name={`${value.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
                value={`${formik.values[type][index].value}`}
                disabled={successSendData}
                sx={{ width: "100%", borderRadius: "14px" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const values = [...formik.values[type]]
                  values[index].value = e.target.value
                  formik.setFieldValue(type, values);
                }} 
                error={ Boolean( errors[type][type+"_"+index] ) }
                helperText={ errors[type][type+"_"+index] }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mb: "30px", pl: "10px" }}>
            <InputLabel shrink className="Input-label">
              Prioridad
            </InputLabel>
            <Box className="TextField-without-border-radius Text-field-filters">
              <TextField
                fullWidth
                name={`${value.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", borderRadius: "14px" }}
                disabled={successSendData}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const values = [...formik.values[type]]
                  values[index].priority = e.target.value
                  formik.setFieldValue(type, values);
                }}
                value={`${formik.values[type][index].priority}`}
                error={ Boolean(errors[type][type+"_priority_" + index]) }
                helperText={ errors[type][type+"_priority_" + index] || "" }
                select
              >
                <MenuItem value={""}></MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </TextField>
            </Box>
          </Grid>
          {
            Number(index) != 0 && (
            <Grid item xs={12} md={1} sx={{ mb: "30px", pl: "10px" }}>
              <Button
                variant="contained"
                color="error"
                disabled={successSendData}
                fullWidth
                onClick={() => deletePosition(Number(index))}
                className="Button-delete-email-enki"
              >
                <CloseIcon sx={{ color: "var(--color-error500)" }} />
              </Button>
            </Grid>
          )}
        </Fragment>
      }  
    ) 
  }
 
  return (
    <>
      <Grid item xs={12} md={12} sx={{ mb: "10px", pl: "10px" }}>
        <Box className="Text-add-form-enki">
          {titleAddArray}
        </Box>
      </Grid>
      { formik.values[type] && componentGrid() }
      <Grid item xs={12} md={12} sx={{ pl: "10px" }}>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            disabled={successSendData || formik.values[type].length == 3 }
            className="Button-add-form-client"
            onClick={addPositions}
          >
            <Box style={{ fontWeight: "bold", margin: 0 }}>
              <Box sx={{ display: "inline-flex", mt: "4px" }}>
                <Typography sx={{ mr: "10px", fontWeight: "700" }}>
                  <AddCircleOutlineIcon />
                </Typography>
                <Typography sx={{ mr: "10px", mt: "3px", fontWeight: "700 !important" }}>
                  {titleAddButton}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default FormArrayClient;
