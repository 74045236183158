import {
  api,
  cleanDataForm,
  getArraysObjectEdit,
  validateArrayEmails,
  validateArrayFields,
  validateArrayPhones,
} from "@enerbit/base";
import {
  DataSendClient,
  DataUpdateSearchUser,
} from "@enerbit/base/common/models/validateArray";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateStorage } from "../../models/StateStorage";
import {
  AccountPersonalization,
  Clients,
  EnkiState,
  OriginalArray,
  ParamsFilter,
  Personalization,
  RootObject,
  ServiceGroupsResponse,
  ServicesGroupsData,
} from "../../models/enki";

const initialState: EnkiState = {
  servicesGroups: [],
  indexUpdateGroup: null,
  isLoadingTableClients: false,
  isLoadingClients: false,
  isLoadingGetServiceAccount: false,
  isLoadingCreateClient: false,
  clients: [],
  personalizations: [],
  count: 0,
  page: 0,
  nextPage: 0,
  appUserIdEdit: "",
  successSendDataFormEdit: "",
  userIdEdit: "",
  errorCreateClient: "",
  successCreateClient: "",
  successSendDataForm: false,
  errorSendDataAccount: "",
  isLoadingSendDataAccount: false,
  clientForm: {},
  params: {},
  errorsArray: {
    emails: {},
    phones: {},
  },
  editClientArray: {
    emails: [],
    phones: [],
  },
};

export const sendDataForm = createAsyncThunk(
  "client/sendDataForm",
  async (data: DataSendClient) => {
    let response = await api.post("/users/app-users/", cleanDataForm(data));
    return response.data;
  }
);

const getEditPhonesEmails = async (
  originalArray: OriginalArray,
  newArray: DataSendClient | DataUpdateSearchUser,
  pii: string
) => {
  const editEmails = getArraysObjectEdit(originalArray, newArray, "emails");

  for await (const emails of editEmails.deletes) {
    await api.delete(`/users/app-users/pii-users/${pii}/emails/${emails.id}`);
  }
  for await (const emails of editEmails.update) {
    await api.patch(
      `/users/app-users/pii-users/${pii}/emails/${emails.id}`,
      emails
    );
  }
  for await (const emails of editEmails.create) {
    await api.post(`/users/app-users/pii-users/${pii}/emails`, emails);
  }

  const arrayObject = { ...originalArray };
  if (!arrayObject.phones) {
    arrayObject.phones = [];
  }

  const editPhones = getArraysObjectEdit(arrayObject, newArray, "phones");

  for await (const phones of editPhones.deletes) {
    await api.delete(`/users/app-users/pii-users/${pii}/phones/${phones.id}`);
  }
  for await (const phones of editPhones.update) {
    await api.patch(
      `/users/app-users/pii-users/${pii}/phones/${phones.id}`,
      phones
    );
  }
  for await (const phones of editPhones.create) {
    await api.post(`/users/app-users/pii-users/${pii}/phones`, phones);
  }
};

export const sendEditClient = createAsyncThunk(
  "client/sendEditClient",
  async (data: DataSendClient, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    delete data.username;
    const cleanData = cleanDataForm(data);
    await getEditPhonesEmails(
      state.enkiState.editClientArray,
      cleanData,
      state.enkiState.clientForm.pii.id
    );
    const response = await api.patch(
      `/users/app-users/${state.enkiState.userIdEdit}/`,
      cleanData
    );
    return response.data.app_user_id;
  }
);

const getDataServiceAccount = async (
  items: AccountPersonalization[]
): Promise<ServicesGroupsData[]> => {
  const servicesPromises = items.map<Promise<ServicesGroupsData>>(
    (serviceAccount) => {
      return new Promise(async (resolve, reject) => {
        try {
          const services = await api.get(`/accounts/service-relationships/`, {
            params: {
              service_account_id: serviceAccount.service_account_id,
              user_id: serviceAccount.user_id,
            },
          });

          return resolve({
            alias:
              serviceAccount.personalizations.length == 0
                ? serviceAccount.id.substring(0, 8)
                : serviceAccount.personalizations[0]?.alias,
            labels: "",
            id: serviceAccount.service_account_id,
            services: services.data,
            user_id: serviceAccount.user_id,
            personalizations: serviceAccount.personalizations,
          });
        } catch (error) {
          return reject(error);
        }
      });
    }
  );

  const promisesResolved = await Promise.allSettled(servicesPromises);
  const servicesResponses = promisesResolved
    .filter((result) => result.status == "fulfilled")
    .map((result: PromiseFulfilledResult<ServicesGroupsData>) => result.value);

  return servicesResponses;
};

export const getServiceAccountsRelationships = createAsyncThunk(
  "client/getServiceAccountsRelationships",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.enkiState.nextPage == null) return [];

    const relationships = await api.get(
      `/accounts/service-accounts-relationships/`,
      {
        params: {
          user_id: state.enkiState.clientForm.id,
          rol_name: "owner",
          size: 15,
          page: state.enkiState.nextPage,
        },
      }
    );

    const itemsPersonalization = await getDataServiceAccount(
      relationships.data.items
    );

    return {
      serviceGroups: itemsPersonalization,
      nextPage: relationships.data.next_page,
    };
  }
);

export const getClients = createAsyncThunk(
  "client/getClients",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    let response = await api.get("/users/app-users/enki/", {
      params: {
        ...state.enkiState.params,
        page: state.enkiState.page,
        size: 10,
      },
    });
    return response.data;
  }
);

export const getUserId = createAsyncThunk(
  "client/getUserId",
  async (editId: string) => {
    let response = await api.get(`/users/app-users/${editId}`);
    return response.data;
  }
);

export const addServicesGroup = createAsyncThunk(
  "client/addServicesGroup",
  async (data: ServicesGroupsData, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const userId = state.enkiState.appUserIdEdit;
    const createAccount = await api.post(`/accounts/service-accounts/`, {
      owner_id: userId,
    });
    await addServicesGroupPersonalization(
      userId,
      data.alias,
      createAccount.data.id
    );
    return {
      ...data,
      id: createAccount.data.id,
      services: [],
      user_id: userId,
    };
  }
);

export const editServicesGroup = createAsyncThunk(
  "client/editServicesGroup",
  async (data: ServicesGroupsData, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    const accountId =
      state.enkiState.servicesGroups[state.enkiState.indexUpdateGroup ?? 0]
        .personalizations[0].id;

    const services =
      state.enkiState.servicesGroups[state.enkiState.indexUpdateGroup ?? 0]
        .services;

    try {
      const { data: dataResp } = await api.patch(
        `/accounts/service-account-personalizations/${accountId}`,
        {
          alias: data.alias,
        }
      );

      const personalizationData = {
        ...data,
        id: dataResp.id,
        personalizations: [
          {
            ...dataResp,
          },
        ],
        services,
      };

      return personalizationData;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to edit service group");
    }
  }
);

export const addServicesGroupPersonalization = async (
  userId: string,
  nameService: string,
  idAccount: string
) => {
  let personalization = await api.post(
    `/accounts/service-account-personalizations/`,
    {
      user_id: userId,
      service_account_id: idAccount,
      alias: nameService,
    }
  );
  return personalization.data.id;
};

export const enkiSlice = createSlice({
  name: "enkiState",
  initialState,
  reducers: {
    validateArrayFieldsState: (
      state,
      action: PayloadAction<DataSendClient>
    ) => {
      const errors = validateArrayFields(action.payload);
      state.errorsArray = { ...errors };
    },
    validateArrayPhonesState: (
      state,
      action: PayloadAction<DataSendClient>
    ) => {
      const errors = validateArrayPhones(action.payload);
      state.errorsArray = { ...state.errorsArray, phones: errors.phones };
    },
    validateArrayEmailsState: (
      state,
      action: PayloadAction<DataSendClient>
    ) => {
      const errors = validateArrayEmails(action.payload);
      state.errorsArray = { ...state.errorsArray, emails: errors.emails };
    },
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIndexUpdateGroup: (state, action: PayloadAction<number | null>) => {
      state.indexUpdateGroup = action.payload;
    },
    setPersonalization: (
      state,
      action: PayloadAction<{
        index: number;
        personalizations: Personalization;
      }>
    ) => {
      state.servicesGroups[action.payload.index] = {
        ...state.servicesGroups[action.payload.index],
        alias: action.payload.personalizations.alias,
        personalizations: [action.payload.personalizations],
      };
    },
    setParams: (state, action: PayloadAction<ParamsFilter>) => {
      state.params = action.payload;
    },
    setEditClientArray: (state, action: PayloadAction<OriginalArray>) => {
      state.editClientArray = action.payload;
    },
    setResetClient: (state) => {
      state.clientForm = {};
      state.params = {};
      state.clients = [];
      state.page = 0;
      state.nextPage = 0;
      state.servicesGroups = [];
      state.appUserIdEdit = "";
      state.userIdEdit = "";
      state.successSendDataForm = false;
    },
    setResetVars: (state) => {
      state.errorSendDataAccount = "";
      state.errorCreateClient = "";
      state.successCreateClient = "";
      state.successSendDataFormEdit = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceAccountsRelationships.pending.type, (state) => {
        state.isLoadingGetServiceAccount = true;
      })
      .addCase(getServiceAccountsRelationships.rejected.type, (state) => {
        state.isLoadingGetServiceAccount = false;
      })
      .addCase(
        getServiceAccountsRelationships.fulfilled.type,
        (state, action: PayloadAction<ServiceGroupsResponse>) => {
          state.servicesGroups = [
            ...(state.servicesGroups ?? []),
            ...(action.payload.serviceGroups ?? []),
          ];
          state.isLoadingGetServiceAccount = false;
          state.nextPage = action.payload.nextPage;
        }
      )
      .addCase(editServicesGroup.pending.type, (state) => {
        state.isLoadingSendDataAccount = true;
      })
      .addCase(
        editServicesGroup.fulfilled.type,
        (state, action: PayloadAction<ServicesGroupsData>) => {
          state.servicesGroups[state.indexUpdateGroup || 0] = action.payload;
          state.errorSendDataAccount = "";
          state.isLoadingSendDataAccount = false;
        }
      )
      .addCase(editServicesGroup.rejected.type, (state) => {
        state.errorSendDataAccount = "Ocurrió un error, inténtelo nuevamente";
        state.isLoadingSendDataAccount = false;
      })
      .addCase(addServicesGroup.pending.type, (state) => {
        state.isLoadingSendDataAccount = true;
      })
      .addCase(
        addServicesGroup.fulfilled.type,
        (state, action: PayloadAction<ServicesGroupsData>) => {
          state.servicesGroups = [...state.servicesGroups, action.payload];
          state.errorSendDataAccount = "";
          state.isLoadingSendDataAccount = false;
        }
      )
      .addCase(addServicesGroup.rejected.type, (state) => {
        state.errorSendDataAccount = "Ocurrió un error, inténtelo nuevamente";
        state.isLoadingSendDataAccount = false;
      })
      .addCase(sendDataForm.pending.type, (state) => {
        state.isLoadingCreateClient = true;
      })
      .addCase(
        sendDataForm.fulfilled.type,
        (state, action: PayloadAction<Clients>) => {
          state.successSendDataForm = true;
          state.clientForm = action.payload;
          state.appUserIdEdit = action.payload.pii.app_user_id;
          state.isLoadingCreateClient = false;
          state.errorCreateClient = "";
          state.successCreateClient = "Cliente creado exitosamente";
        }
      )
      .addCase(sendDataForm.rejected.type, (state) => {
        state.errorCreateClient = "Ocurrió un error, intentélo de nuevo";
        state.isLoadingCreateClient = false;
        state.successCreateClient = "";
      })
      .addCase(sendEditClient.pending.type, (state) => {
        state.isLoadingCreateClient = true;
      })
      .addCase(
        sendEditClient.fulfilled.type,
        (state, action: PayloadAction<string>) => {
          state.successSendDataFormEdit = "Usuario actualizado exitosamente";
          state.appUserIdEdit = action.payload;
          state.isLoadingCreateClient = false;
          state.errorCreateClient = "";
        }
      )
      .addCase(sendEditClient.rejected.type, (state) => {
        state.errorCreateClient = "Ocurrió un error, intentélo de nuevo";
        state.isLoadingCreateClient = false;
        state.successSendDataFormEdit = "";
      })
      .addCase(getClients.pending.type, (state) => {
        state.isLoadingTableClients = true;
      })
      .addCase(
        getClients.fulfilled.type,
        (state, action: PayloadAction<RootObject>) => {
          state.isLoadingTableClients = false;
          state.clients = action.payload.items;
          state.count = action.payload.pages;
        }
      )
      .addCase(getClients.rejected.type, (state) => {
        state.isLoadingTableClients = false;
      })
      .addCase(getUserId.pending.type, (state) => {
        state.isLoadingClients = true;
      })
      .addCase(
        getUserId.fulfilled.type,
        (state, action: PayloadAction<Clients>) => {
          state.isLoadingClients = false;
          state.clientForm = action.payload;
          state.appUserIdEdit = action.payload.pii.app_user_id;
          state.userIdEdit = action.payload.pii.id;
        }
      )
      .addCase(getUserId.rejected.type, (state) => {
        state.isLoadingClients = false;
        state.appUserIdEdit = "";
        state.userIdEdit = "";
      });
  },
});

export const {
  setIndexUpdateGroup,
  handlePageChange,
  setResetClient,
  setParams,
  setResetVars,
  validateArrayFieldsState,
  validateArrayEmailsState,
  validateArrayPhonesState,
  setEditClientArray,
  setPersonalization,
} = enkiSlice.actions;

export const servicesGroups = (state: StateStorage) =>
  state.enkiState.servicesGroups;
export const indexUpdateGroup = (state: StateStorage) =>
  state.enkiState.indexUpdateGroup;
export const appUserIdEdit = (state: StateStorage) =>
  state.enkiState.appUserIdEdit;
export const userIdEdit = (state: StateStorage) => state.enkiState.userIdEdit;
export const count = (state: StateStorage) => state.enkiState.count;
export const clients = (state: StateStorage) => state.enkiState.clients;
export const page = (state: StateStorage) => state.enkiState.page;
export const isLoadingTableClients = (state: StateStorage) =>
  state.enkiState.isLoadingTableClients;
export const params = (state: StateStorage) => state.enkiState.params;
export const isLoadingClients = (state: StateStorage) =>
  state.enkiState.isLoadingClients;
export const clientForm = (state: StateStorage) => state.enkiState.clientForm;
export const successSendDataForm = (state: StateStorage) =>
  state.enkiState.successSendDataForm;
export const errorSendDataAccount = (state: StateStorage) =>
  state.enkiState.errorSendDataAccount;
export const isLoadingSendDataAccount = (state: StateStorage) =>
  state.enkiState.isLoadingSendDataAccount;
export const errorCreateClient = (state: StateStorage) =>
  state.enkiState.errorCreateClient;
export const isLoadingCreateClient = (state: StateStorage) =>
  state.enkiState.isLoadingCreateClient;
export const personalizations = (state: StateStorage) =>
  state.enkiState.personalizations;
export const successSendDataFormEdit = (state: StateStorage) =>
  state.enkiState.successSendDataFormEdit;
export const isLoadingGetServiceAccount = (state: StateStorage) =>
  state.enkiState.isLoadingGetServiceAccount;
export const errorsArray = (state: StateStorage) => state.enkiState.errorsArray;
export const editClientArray = (state: StateStorage) =>
  state.enkiState.editClientArray;
export const successCreateClient = (state: StateStorage) =>
  state.enkiState.successCreateClient;

export default enkiSlice.reducer;
