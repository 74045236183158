import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  getMagicalParamUrl,
  IconButton,
} from "@enerbit/base";
import FormClient from "./FormClient";
import FormServiceGroup from "./FormServiceGroup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getServiceAccountsRelationships,
  getUserId,
  setResetClient,
  setResetVars,
} from "../../../features/enki/enki";
import { AppDispatch } from "../../../store/store";
import { useEffect } from "react";

const Index = () => {
  const getInitialParams = async () => {
    const param = getMagicalParamUrl();
    if (param) {
      dispatch(setResetClient());
      await dispatch(getUserId(param.toString().replace("q=", "")));
      await dispatch(getServiceAccountsRelationships());
    }
  };

  useEffect(() => {
    getInitialParams();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box className="Component-root">
      <Box>
        <IconButton
          sx={{ padding: "1px !important" }}
          onClick={() => {
            dispatch(setResetClient());
            dispatch(setResetVars());
            navigate("/");
          }}
        >
          <ArrowCircleLeftOutlinedIcon className="Icon-back" />
        </IconButton>
      </Box>
      <FormClient />
      <FormServiceGroup />
    </Box>
  );
};

export default Index;
