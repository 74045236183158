import {
  BoltOutlinedIcon,
  Box,
  CustomModal,
  ElectricitySupplyService,
  ForestOutlinedIcon,
  Grid,
  WbIncandescentOutlinedIcon,
} from "@enerbit/base";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAccountsRelationships } from "../../../../features/enki/enki";
import { mountedApps } from "../../../../helpers/helperSingleSpaMounted";
import { StateStorage } from "../../../../models/StateStorage";
import { CustomModalTypes } from "../../../../models/modalProps";
import { AppDispatch } from "../../../../store/store";

const CreateModalService = forwardRef((props, ref) => {
  const createService = useRef<CustomModalTypes>(null);
  const { clientForm } = useSelector((state: StateStorage) => state.enkiState);
  const dispatch = useDispatch<AppDispatch>();

  useImperativeHandle(ref, () => ({
    changeModal(serviceAccountId: string, userId: string) {
      if (createService.current) {
        createService.current.changeModal();
        mountedApps({
          leadUserEmail: clientForm.username,
          serviceAccountId: serviceAccountId,
          userId: userId,
          onCloseModal: (electricitySupplyService: ElectricitySupplyService) => {
            createService.current.changeModal();
            // TODO: Actualizar el state con el servicio que llega
            dispatch(getServiceAccountsRelationships());
          },
        });
      }
    },
  }));

  const [filterSelected, setFilterSelected] = useState("Ener. Convencional");

  const filtersSelected = [
    {
      value: "enerTree",
      icon: <ForestOutlinedIcon className={"Icon-search-filter"} />,
      component: <Box></Box>,
    },
    {
      value: "Ener. Convencional",
      icon: <BoltOutlinedIcon className="Icon-search-filter" />,
      component: <Box sx={{ width: "100%" }} id="single-spa-application:app-electricity-supply-service-create"></Box>,
    },
    {
      value: "Ener. Digital",
      icon: <WbIncandescentOutlinedIcon className="Icon-search-filter" />,
      component: <Box sx={{ width: "100%" }} id="single-spa-application:app-home"></Box>,
    },
  ];

  return (
    <CustomModal
      maxWidth="md"
      ref={createService}
      dialogContent={
        <>
          <Box>
            <Box className="Header-client" sx={{ fontWeight: "600" }}>
              Creación de servicio
            </Box>
            <Box className="Header-service-selected">Selecciona el servicio que necesites</Box>
            <Box>
              <Grid container spacing={2}>
                {filtersSelected.map((value, index) => (
                  <Grid item xs={4} md={4} key={index}>
                    <Box
                      className={
                        value.value == filterSelected ? "Card-search-filter-enki selected" : "Card-search-filter-enki"
                      }
                      onClick={() => setFilterSelected(value.value)}
                    >
                      <Box className="Icon--search-filter">{value.icon}</Box>
                      <Box className="Label-search-filter">{value.value}</Box>
                    </Box>
                  </Grid>
                ))}
                {filtersSelected.map((value, index) => (
                  <Box
                    sx={{
                      display: value.value == filterSelected ? "block" : "none",
                      width: "100%",
                    }}
                    key={index}
                  >
                    {" "}
                    {value.component}{" "}
                  </Box>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      }
    />
  );
});

export default CreateModalService;
