import { ElectricitySupplyService } from "@enerbit/base";
import * as singleSpa from "single-spa";

export interface ParamsServiceCreateMicro {
  leadUserEmail: string;
  serviceAccountId: string;
  userId: string;
  onCloseModal: (electricitySupplyService: ElectricitySupplyService) => void;
}

const mountedAppSingleSpaElectricityService = (
  params: ParamsServiceCreateMicro
) => {
  singleSpa.registerApplication(
    "app-electricity-supply-service-create",
    (): Promise<singleSpa.LifeCycles> =>
      System.import("@enerBit/electricity-supply-service-create"),
    (location) => location.pathname.startsWith("/"),
    params
  );
};

export const mountedApps = async (params: ParamsServiceCreateMicro) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-electricity-supply-service-create");

  if (appNamesAccess) {
    singleSpa
      .unregisterApplication("app-electricity-supply-service-create")
      .then(async () => {
        await mountedAppSingleSpaElectricityService(params);
      });
  } else {
    await mountedAppSingleSpaElectricityService(params);
  }
};
