import { configureStore } from "@reduxjs/toolkit"; 
import enkiReducer from "../features/enki/enki";

export const store = configureStore({
  reducer: { 
    enkiState: enkiReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
