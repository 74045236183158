import {
  Box, 
  Grid,
  Button,
  CircularProgress,
} from "@enerbit/base";
 
import FiltersEnki from "./reusable/FiltersEnki";
import TableEnki from "./reusable/TableEnki";
import { useNavigate } from 'react-router-dom'; 
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import { getClients, isLoadingTableClients, setResetClient } from "../features/enki/enki";

const SectionEnki = () => { 

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingTableClients);  

  useEffect(() => {
    dispatch(setResetClient());
    dispatch(getClients());  
  }, [])

  return (
    <Box className="Component-root">
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid item xs={12} md={10} sx={{mb: "30px"}}> 
          <Box className="Title-main-information">Enki</Box>
        </Grid>
        <Grid item xs={12} md={2}> 
          <Button 
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              navigate('/create')
            }}
            className="Button-create-client-enki"
          >
            <Box>Nuevo cliente</Box>
          </Button>
        </Grid>
      </Grid>
      <Box>
        <FiltersEnki/>
        {
          loading ?
            <Box className="Loading-flex">
              <CircularProgress />
            </Box>
          : 
          ( 
            <TableEnki/>
          )
        } 
      </Box>
    </Box>

  );
};

export default SectionEnki;
