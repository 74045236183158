import {
  AddCircleOutlineIcon,
  AddIcon,
  Box,
  Button,
  CircularProgress,
  EditIcon,
  Grid,
  InputLabel,
  Typography,
} from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  appUserIdEdit,
  isLoadingGetServiceAccount,
  servicesGroups,
  setIndexUpdateGroup,
  personalizations as personalizationsSelector,
  getServiceAccountsRelationships,
} from "../../../features/enki/enki";
import {
  CustomModalTypes,
  CustomModalTypesCreate,
} from "../../../models/modalProps";
import { AppDispatch } from "../../../store/store";
import ContainerServices from "../ContainerServices";
import CreateModalClient from "./modal/CreateModalClient";
import CreateModalService from "./modal/CreateModalService";
import { ServicesGroupsData } from "../../../models/enki";

const FormServiceGroup = () => {
  const [serviceGroup, setServiceGroup] = useState<ServicesGroupsData | null>(
    null
  );
  const [userID, setUserID] = useState("");
  const createClient = useRef<CustomModalTypes>(null);
  const createService = useRef<CustomModalTypesCreate>(null);
  const servicesGroup = useSelector(servicesGroups);
  const personalizations = useSelector(personalizationsSelector);
  const validationCreate = useSelector(appUserIdEdit);
  const loadingGetServiceAccount = useSelector(isLoadingGetServiceAccount);
  const dispatch = useDispatch<AppDispatch>();
  const callCreateClient = (
    index?: number,
    service_group?: ServicesGroupsData
  ) => {
    if (createClient.current) {
      createClient.current.changeModal();
    }
    if (service_group) {
      setServiceGroup(service_group);
    }
    if (typeof index === "number") {
      dispatch(setIndexUpdateGroup(index));
    }
  };
  const callCreateService = (serviceAccountId: string, userId: string) => {
    if (createService.current) {
      createService.current.changeModal(serviceAccountId, userId);
    }
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      if(loadingGetServiceAccount) return;
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        dispatch(getServiceAccountsRelationships());
      }
    };

    const serviceGroupList = document.getElementById("Service-group-list");
    serviceGroupList.addEventListener("scroll", handleScroll);
    return () => {
      serviceGroupList.removeEventListener("scroll", handleScroll);
    };
  }, [loadingGetServiceAccount]);

  return (
    <Box minHeight={700}>
      <Box className="Container-client">
        <Box className="Header-client"> Grupo de servicios </Box>
        <Box>
          {servicesGroup.length == 0 && !loadingGetServiceAccount && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={validationCreate == ""}
                color="primary"
                fullWidth
                className="Container-button-create-group-service"
                onClick={() => callCreateClient()}
              >
                <Box style={{ fontWeight: "bold", margin: 0 }}>
                  <Box className="Container-create-group-service">
                    <Typography className="Add-circle-outline-icon">
                      <AddCircleOutlineIcon
                        sx={{ width: "1rem", height: "1rem" }}
                      />
                    </Typography>
                    <Typography className="Text-create-group-service">
                      Crear grupo de servicio
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Box>
          )}
          <Box>
            {servicesGroup.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="Loading-button Button-xs"
                  onClick={() => callCreateClient()}
                  sx={{ ml: "10px" }}
                >
                  <Box style={{ fontWeight: "bold", margin: 0 }}>
                    Nuevo grupo
                  </Box>
                </Button>
              </Box>
            )}

            <Box height={600} marginTop={10} id="Service-group-list" overflow="auto">
              {servicesGroup.map((values, index) => (
                <Grid container className="Container-service-group" key={index}>
                  <Grid item xs={3}>
                    <InputLabel
                      shrink
                      className="Input-label"
                      sx={{ fontSize: "0.75rem" }}
                    >
                      Nombre grupo servicios
                    </InputLabel>
                    <Box className="Group-service-item">{values.alias}</Box>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel
                      shrink
                      className="Input-label"
                      sx={{ fontSize: "0.75rem" }}
                    >
                      Etiquetas
                    </InputLabel>
                    <Box className="Group-service-item">{values.labels}</Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box className="Container-groups-client">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => callCreateClient(index, values)}
                        sx={{ minWidth: "59px", borderRadius: "14px" }}
                        className="Loading-button"
                      >
                        <EditIcon sx={{ fontSize: "21px" }} />
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="Loading-button Button-xs"
                        onClick={() => {
                          callCreateService(values.id, values.user_id);
                        }}
                        startIcon={<AddIcon />}
                        sx={{ ml: "10px" }}
                      >
                        <Box style={{ fontWeight: "bold", margin: 0 }}>
                          Nuevo servicio
                        </Box>
                      </Button>
                    </Box>
                  </Grid>
                  {values.services && (
                    <ContainerServices services={values.services} />
                  )}
                </Grid>
              ))}
              {loadingGetServiceAccount && (
                <Box className="Loading-flex">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>

          <CreateModalClient ref={createClient} service_group={serviceGroup} />
          <CreateModalService ref={createService} />
        </Box>
      </Box>
    </Box>
  );
};
export default FormServiceGroup;
