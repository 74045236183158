import {
  Box,
  CustomAlert,
  CustomModal,
  InputLabel,
  LoadingButton,
  TextField,
  useFormik,
  yup,
} from "@enerbit/base";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addServicesGroup,
  addServicesGroupPersonalization,
  editServicesGroup,
  errorSendDataAccount,
  indexUpdateGroup,
  isLoadingSendDataAccount,
  servicesGroups,
  setIndexUpdateGroup,
  setPersonalization,
  setResetVars,
} from "../../../../features/enki/enki";
import { CustomModalTypes } from "../../../../models/modalProps";
import { AppDispatch } from "../../../../store/store";
import { ServicesGroupsData } from "../../../../models/enki";

interface CreateModalClientProps {
  service_group?: ServicesGroupsData;
  UserId?: string;
}

const CreateModalClient = forwardRef<CustomModalTypes, CreateModalClientProps>(
  ({ service_group }, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const createClient = useRef<CustomModalTypes>(null);
    const indexUpdate = useSelector(indexUpdateGroup);
    const groupServices = useSelector(servicesGroups);
    const errorDataAccount = useSelector(errorSendDataAccount);
    const loadingDataAccount = useSelector(isLoadingSendDataAccount);
    const [sendForm, setSendForm] = useState(false);

    useImperativeHandle(ref, () => ({
      changeModal() {
        if (createClient?.current) {
          createClient.current.changeModal();
        }
      },
    }));

    const initialValues = {
      alias: "",
      labels: "",
    };

    const validationSchema = yup.object({
      alias: yup
        .string()
        .required("El nombre del grupo de servicio es requerido"),
      labels: yup.string(),
    });

    let formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        if (
          indexUpdate !== null &&
          service_group &&
          service_group?.personalizations.length > 0
        ) {
          await dispatch(editServicesGroup(values as any));
        } else if (
          indexUpdate !== null &&
          service_group &&
          service_group?.personalizations.length === 0
        ) {
          const newId = await addServicesGroupPersonalization(
            service_group?.user_id,
            values.alias,
            service_group?.id
          );
          dispatch(
            setPersonalization({
              index: indexUpdate,
              personalizations: {
                user_id: service_group?.user_id,
                service_account_id: service_group?.id,
                alias: values.alias,
                id: newId,
              },
            })
          );
        } else if (indexUpdate === null) {
          await dispatch(addServicesGroup(values as any));
        }

        setSendForm(true);
      },
    });

    useEffect(() => {
      if (createClient.current && errorDataAccount == "" && sendForm) {
        createClient.current.changeModal();
        formik.resetForm();
        dispatch(setIndexUpdateGroup(null));
        dispatch(setResetVars());
        setSendForm(false);
      }
    }, [sendForm, errorDataAccount]);

    useEffect(() => {
      if (indexUpdate != null) {
        const values = groupServices[indexUpdate];
        formik.setFieldValue("labels", values.labels);
        formik.setFieldValue("alias", values.alias);
      }
    }, [indexUpdate]);

    return (
      <CustomModal
        maxWidth="md"
        ref={createClient}
        onClose={() => {
          formik.resetForm();
          dispatch(setIndexUpdateGroup(null));
        }}
        dialogContent={
          <>
            <form onSubmit={formik.handleSubmit}>
              <Box className="Header-client" sx={{ fontWeight: "600" }}>
                {indexUpdate != null ? "Editar" : "Nuevo"} grupo de servicios
              </Box>
              <InputLabel shrink className="Input-label">
                Nombre grupo de servicios
              </InputLabel>
              <Box className="TextField-without-border-radius Text-field-filters">
                <TextField
                  fullWidth
                  name="alias"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%", borderRadius: "14px" }}
                  value={formik.values.alias}
                  onChange={formik.handleChange}
                  error={formik.touched.alias && Boolean(formik.errors.alias)}
                  helperText={formik.touched.alias && formik.errors.alias}
                />
              </Box>
              <InputLabel shrink className="Input-label">
                Etiquetas
              </InputLabel>
              <Box className="TextField-without-border-radius Text-field-filters">
                <TextField
                  fullWidth
                  name="labels"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  sx={{ width: "100%", borderRadius: "14px" }}
                  value={formik.values.labels}
                  onChange={formik.handleChange}
                  error={formik.touched.labels && Boolean(formik.errors.labels)}
                  helperText={formik.touched.labels && formik.errors.labels}
                />
              </Box>
              {errorDataAccount != "" && (
                <CustomAlert
                  onClose={() => {
                    dispatch(setResetVars());
                  }}
                  sx={{ width: "100%", mb: "20px", mt: "10px" }}
                  severity="error"
                  text={errorDataAccount}
                />
              )}
              <Box sx={{ width: "5rem", margin: "0.8rem 0 0 auto" }}>
                <LoadingButton
                  variant="contained"
                  color="warning"
                  size="small"
                  type="submit"
                  className="Loading-button"
                  sx={{ width: "93%" }}
                  loading={loadingDataAccount}
                >
                  <Box style={{ fontWeight: "700", margin: 0, color: "white" }}>
                    {indexUpdate != null ? "Editar" : "Guardar"}
                  </Box>
                </LoadingButton>
              </Box>
            </form>
          </>
        }
      />
    );
  }
);

export default CreateModalClient;
