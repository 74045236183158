import { getPriority } from "@enerbit/base";
import { DataSendClient } from "@enerbit/base/common/models/validateArray";
import { StructureArrayClients } from "../models/enki";

export const formatterFieldsArraySend = (
  array: StructureArrayClients[],
  label: string
) => {
  return array.map((item, index) => ({
    [label]: item.value,
    priority: item.priority,
    id: item.id,
  }));
};

export const getBodySendClient = (data): DataSendClient => {
  const emails = formatterFieldsArraySend(data.emails, "email");
  const username = getPriority(emails)?.email ?? "";
  const splittedUsername = username.split("@");

  return {
    emails: emails,
    phones: formatterFieldsArraySend(data.phones, "phone"),
    username: username,
    city: "",
    country: "",
    password: splittedUsername.length > 0 ? splittedUsername[0] : "",
    names: data.names?.trim(),
    last_names: data.last_names?.trim() || undefined,
    legal_id_type: data.legal_id_type?.trim() || undefined,
    legal_id_code: data.legal_id_code?.trim() || undefined,
    verification_code: data.verification_code || undefined,
  };
};
