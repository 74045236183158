import {
  Box,
  Grid,
  Button,
  TextField,
  InputAdornment,
  SearchIcon,
  Typography,
  KeyboardArrowDownIcon
} from "@enerbit/base";
import { useRef } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { getClients, isLoadingTableClients, params, setParams, setResetClient } from "../../features/enki/enki";
import { ParamsFilter } from "../../models/enki";
import { AppDispatch } from "../../store/store";

const FiltersEnki = () => {
  const filters = useSelector(params);
  let timeout: NodeJS.Timeout; 
  const textInput = useRef<typeof TextField>(null);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingTableClients);  

  const changeParams = (value: ParamsFilter) => {
    dispatch(setResetClient());
    dispatch(setParams({...filters, ...value})); 
    dispatch(getClients())
  }  
  
  return (
    <>
    <Grid sx={{ flexGrow: 1 }} container>
      <Grid item xs={12} md={10} sx={{mb: "30px"}}> 
        <Box sx={{ width: "40%" }}>
          <Box sx={{mb: "10px", color: "var(--color-neutral700)"}}>Buscar</Box>  
          <Box className="TextField-without-border-radius Text-select-field"> 
            <TextField 
              disabled={loading}
              inputRef={textInput}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                clearInterval(timeout);
                timeout = setInterval(() => {  
                  changeParams({pattern: event.target.value})
                  clearInterval(timeout);
                }, 500); 
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{color: "var(--color-neutral700)", width: "16px", height: "16px"}}/>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Ej: example@correo.com"
              sx={{ width: "100%", borderRadius: "14px"}}
            />
          </Box>
        </Box> 
      </Grid>
      <Grid item xs={12} md={2}> 
        <Button 
          variant="outlined" 
          fullWidth
          sx={{maxWidth: "170px", borderColor: "rgba(52,64,84,1)"}}
        >
          <Box style={{fontWeight: "bold", margin: 0}}>
            <Box sx={{display: "inline-flex", mt: "4px"}}> 
              <Typography sx={{mr:"10px", mt: "3px", color: "rgba(52,64,84,1)", fontWeight: "700"}}> 
                Ordenar por  
              </Typography>
              <Typography> 
                <KeyboardArrowDownIcon sx={{color: "rgba(52,64,84,1)"}} />  
              </Typography> 
            </Box>   
          </Box>
        </Button>
      </Grid>
    </Grid>
    </>
  );
};

export default FiltersEnki;
